/*
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-10-17 17:40:16
 * @LastEditTime: 2019-10-17 17:40:16
 * @LastEditors: your name
 */
import iView from 'view-design'
import 'view-design/dist/styles/iview.css'

function findComponentUpward(context, componentName, componentNames) {
  if (typeof componentName === 'string') {
    componentNames = [componentName]
  } else {
    componentNames = componentName
  }

  let parent = context.$parent
  let name = parent.$options.name
  while (parent && (!name || componentNames.indexOf(name) < 0)) {
    parent = parent.$parent
    if (parent) name = parent.$options.name
  }
  return parent
}

iView.InputNumber.props.activeChange = {
  type: Boolean,
  default: false
}

iView.InputNumber.methods.blur = function() {
  this.$nextTick(() => {
    this.focused = false
    this.$emit('on-blur')
    if (
      !findComponentUpward(this, [
        'DatePicker',
        'TimePicker',
        'Cascader',
        'Search'
      ])
    ) {
      this.dispatch('FormItem', 'on-form-blur', this.currentValue)
    }
  })
}

export default iView

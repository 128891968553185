/*
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-10-17 17:10:07
 * @LastEditTime: 2019-10-25 16:42:02
 * @LastEditors: Please set LastEditors
 */
import Vue from 'vue'
import App from './App.vue'

// router
import router from './router'

// plugin
import plugin from './plugin'
Vue.use(plugin)

// components
import components from './components'
Vue.use(components)

// vue-awesome-swiper
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
Vue.use(VueAwesomeSwiper)

import './style/base.scss'

import './style/iconfont/iconfont.css'

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')


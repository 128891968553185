/*
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-10-17 17:35:37
 * @LastEditTime: 2019-10-17 17:35:37
 * @LastEditors: your name
 */
// 动态注册compontents内的所有组件，之后在全局无需注册即可使用
// 格式为  目录名-文件名，
// 例子1  src/components/page/header.vue, <page-header>
// 例子2  src/components/Hello.vue, <hello>

/* 将组件注册成 目录-文件名的形式, page-header
 * 无目录则为文件名，如 hello
 */
function rename(key) {
  const name = key
    .replace(/\.+\//, '')
    .replace(/\.vue/, '')
    .replace(/\//g, '-')
    .replace(/^(\w)/, v => v.toLowerCase())
  return name
}

const requireContext = require.context('./', true, /.*\.vue$/)

const components = requireContext.keys().map(key => ({
  name: rename(key),
  component: requireContext(key).default
}))

const install = Vue => {
  if (install.installed) return
  install.installed = true

  components.forEach(el => {
    Vue.component(el.name, el.component)
  })
}

export default install

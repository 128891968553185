<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-10-18 16:18:44
 * @LastEditTime: 2019-10-25 15:19:57
 * @LastEditors: Please set LastEditors
 -->
<template>
  <div class="layout">
    <main class="index index-bg text-center">
      <div class="index-title ft-white">
        <div class="ft52">
          专业的商务电子合同签署专家
        </div>
        <p class="ft24 presentation">
          面向个人和团队，提供快捷可靠的签署和查证协作平台
        </p>
      </div>

      <Row type="flex"
           justify="center"
           class="code-row-bg">
        <Col class="index-main index-sign">
        <!-- <img class="index-img" src="static/img/home_sign.png" alt> -->
        <div class="index-img-sign"></div>
        <p class="sign-title ft20">
          立即签署电子合同
        </p>
        <span class="top-line">上传一份电子文档去签署</span>

        </Col>
        <Col class="index-interval">
        </Col>
        <Col class="index-main index-find"
             @click.native="check()">
        <div class="index-img-find"></div>
        <p class="find-title ft20">
          合同存证查询
        </p>
        <span class="top-line">查询在鸽纸平台上签署的合同真伪</span>
        </Col>
      </Row>
    </main>
    <div class="container">
      <div class="title ft46 text-center">
        快速完成合同签署，更多时间留给您
      </div>
      <div class="show">
        <Row class="show-bottom code-row-bg"
             type="flex"
             justify="space-between">
          <Col class="show-detail">
          <p class="show-title">
            签署合同更快捷，性价比更高
          </p>
          <div class="show-content">
            比传统纸质合同更快捷，省去来回邮寄的等待。
            <br>简洁的签署流程、友好的使用界面，更方便易用。
            <br>签署和存储合同平均比同行便宜20%以上。
          </div>
          <a href="javascript:void(0)">产品详情</a>
          </Col>
          <Col>
          <img src="static/img/home_img_1.png"
               alt>
          </Col>
        </Row>
        <Row class="show-bottom code-row-bg"
             type="flex"
             justify="space-between">
          <Col>
          <img src="static/img/home_img_2.png"
               alt>
          </Col>
          <Col class="show-detail">
          <p class="show-title">
            三步完成电子签署
          </p>
          <div class="show-content">
            1、添加合同文本；
            <br>2、签署合同；
            <br>3、发送电子合同。
          </div>
          <a href="javascript:void(0)">产品详情</a>
          </Col>
        </Row>
        <Row class="show-bottom code-row-bg"
             type="flex"
             justify="space-between">
          <Col class="show-detail">
          <p class="show-title">
            云合约管理
          </p>
          <div class="show-content">
            对于团队的合同管理，可以有效避免信息在团队内孤岛化。
            <br>无论在哪儿，只需打开浏览器，随时可以查看合约，即时分享给
            <br>团队，交接流程更高效。
          </div>
          <a href="javascript:void(0)">产品详情</a>
          </Col>
          <Col>
          <img src="static/img/home_img_3.png"
               alt>
          </Col>
        </Row>
        <Row class="show-bottom code-row-bg"
             type="flex"
             justify="space-between">
          <Col>
          <img src="static/img/home_img_4.png"
               alt>
          </Col>

          <div class="show-detail">
            <p class="show-title">
              两种模式签署合同
            </p>
            <div class="show-content">
              1、甲方通过web网页邀请乙方签署，乙方可以选择在手机上，也可以选择在web上签署。
              <br>2、线下当面签署，甲乙双方在同一个设备上完成签署。
            </div>
            <a href="javascript:void(0)">产品详情</a>
          </div>
        </Row>
      </div>

      <div class="title ft46 text-center">
        金融级数据安全，全方位防止合同被篡改
      </div>
      <div class="safe">
        <Row type="flex"
             justify="space-between"
             class="code-row-bg text-center safe-bottom">
          <Col span="6">
          <div class="safe-img">
            <img src="static/img/safe_icon_1.png"
                 class="img-w92"
                 alt>
          </div>
          <div class="safe-title">
            SSL加密技术
          </div>
          <div>鸽纸电签使用国际领先的SSL加密技术来确保用户和平台的链接安全可靠。</div>
          </Col>
          <Col span="6">
          <div class="safe-img">
            <img src="static/img//safe_icon_2.png"
                 class="img-w92"
                 alt>
          </div>
          <div class="safe-title">
            区块链技术
          </div>
          <div>存证用区块链搭载于IBM Hyperledger，链上存证系统可以验证电子证据的真实性和有效性，已上链的电子证据无法被修改。</div>
          </Col>
          <Col span="6">
          <div class="safe-img">
            <img src="static/img/safe_icon_3.png"
                 class="img-w92"
                 alt>
          </div>
          <div class="safe-title">
            三大法律保护
          </div>
          <div>符合《中华人民共和国电子签名法》、UETA和eIDS条例，随时提供电签相关的法律支持与专业的电子合同证据体系</div>
          </Col>
        </Row>
        <Row type="flex"
             justify="space-between"
             class="code-row-bg text-center safe-bottom">
          <Col span="6">
          <div class="safe-img">
            <img src="static/img/safe_icon_4.png"
                 class="img-w92"
                 alt>
          </div>
          <div class="safe-title">
            阿里云服务器
          </div>
          <div>用户数据、合同内容、签署所产生的证据数据全部被加密存储在安全级别最高的阿里云服务器上，任何修改都会被记录在案。</div>
          </Col>
          <Col span="6">
          <div class="safe-img">
            <img src="static/img/safe_icon_5.png"
                 class="img-w92"
                 alt>
          </div>
          <div class="safe-title">
            人脸识别
          </div>
          <div>电签联合公安部和数字认证中心，应用人脸识别技术与活体检测系统，确保签名主体的真实性和有效性。</div>
          </Col>
          <Col span="6">
          <div class="safe-img">
            <img src="static/img/safe_icon_6.png"
                 class="img-w92"
                 alt>
          </div>
          <div class="safe-title">
            存证系统
          </div>
          <div>拥有自主知识产权的加密技术，存证用区块链配合云服务器，保证文件存储的安全。</div>
          </Col>
        </Row>

        <div class="title ft46 text-center">
          鸽纸电签已获得CFCA数字证书合作协议
        </div>
        <Row class="text-center code-row-bg cfca"
             type="flex"
             justify="space-between">
          <Col span="4">
          <img src="static/img/cfca_icon_id.png"
               class="img-w92"
               alt>
          <div>cfca数字证书，确保电签用户的身份真实性</div>
          </Col>
          <Col span="4">
          <img src="static/img/cfca_icon_law.png"
               class="img-w92"
               alt>
          <div>国家认证机构背书，保证电子合同合法性</div>
          </Col>
          <Col span="4">
          <img src="static/img/cfca_icon_sign.png"
               class="img-w92"
               alt>
          <div>cfca数字签名，保障电签及过程中产生的证据的不可抵赖性和可追溯性</div>
          </Col>
          <Col span="4">
          <img src="static/img/cfca_icon_time.png"
               class="img-w92"
               alt>
          <div>cfca为文件加盖时间戳，同时提供标准时间获取，保证截标日期的公正性</div>
          </Col>
        </Row>

        <div class="immediately">
          <div class="immediately-title">
            现在就使用鸽纸，开始快捷电签
          </div>
          <div class="immediately-btn">
            立即体验
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {}
  },
  methods: {
    check() {
      console.log('123')
      window.location.href = 'http://www.covenate.cn/check'
    }
  }
}
</script>
<style lang="scss">
.layout {
  .index {
    width: 100%;
    height: 811px;
    .index-title {
      padding: 20vh 0 8vh 0;
    }
    .index-main {
      width: 28vw;
      height: 37vh;
      @include themeify {
        background-color: themed("color-white");
      }
      box-shadow: 0px 2px 20px 0px rgba(52, 102, 134, 1);
      border-radius: 6px;
      min-height: 300px;
      cursor: pointer;
      .index-img-sign {
        width: 56px;
        height: 56px;
        margin: 0 auto;
        margin-top: 66px;
        margin-bottom: 20px;
        background: url("/static/img/home_sign.png") no-repeat;
        background-size: contain;
      }
      .index-img-find {
        width: 56px;
        height: 56px;
        margin: 0 auto;
        margin-top: 66px;
        margin-bottom: 20px;
        background: url("/static/img/home_find.png") no-repeat;
        background-size: contain;
      }
      .sign-title {
        @include themeify {
          color: themed("color-primary");
        }
        padding-bottom: 40px;
      }
      .find-title {
        @include themeify {
          color: themed("color-secondary");
        }
        padding-bottom: 40px;
      }
      .top-line {
        display: inline-block;
        padding: 34px;
        border-top: 1px solid #dfdfdf;
      }
    }
    .index-interval {
      width: 2.2vw;
    }
    .index-sign:hover {
      border-radius: 6px;
      @include themeify {
        border: 3px solid themed("color-primary");
      }
      box-shadow: unset;
      .index-img-sign {
        background: url("/static/img/home_sign_selected.png") no-repeat;
        background-size: contain;
      }
    }
    .index-find:hover {
      border-radius: 6px;
      @include themeify {
        border: 3px solid themed("color-secondary");
      }
      box-shadow: unset;
      .index-img-find {
        background: url("/static/img/home_find_selected.png") no-repeat;
        background-size: contain;
      }
    }
    .presentation {
      padding-bottom: 90px;
    }
  }
  .container {
    .img-w92 {
      width: 92px;
    }
    .title {
      @include themeify {
        color: themed("color-black");
      }
      padding: 100px 0;
    }
    .cfca {
      padding-bottom: 120px;
    }
    .show img {
      width: 647px;
      height: 100%;
    }

    .show a {
      display: inline-block;
      margin-top: 50px;
    }
    .show-detail {
      margin-bottom: 50px;
      width: 490px;
    }
    .show-bottom {
      margin-bottom: 140px;
    }
    .show-title {
      font-size: 32px;
      font-weight: 400;
      @include themeify {
        color: themed("color-black");
      }
      line-height: 45px;
      padding-bottom: 30px;
    }
    .safe {
      .safe-bottom {
        margin-bottom: 90px;
      }

      .safe-title {
        padding-top: 41px;
        padding-bottom: 22px;
        font-size: 24px;
        font-weight: 300;
        @include themeify {
          color: themed("color-black");
        }
      }
    }

    .safe-bottom {
      margin-bottom: 90px;
    }

    .safe-title {
      padding-top: 41px;
      padding-bottom: 22px;
      font-size: 24px;
      font-weight: 300;
      @include themeify {
        color: themed("color-black");
      }
    }

    .immediately {
      width: 100%;
      height: 365px;
      @include gradient;
      border-radius: 31px;
      margin-bottom: 100px;
      text-align: center;
    }

    .immediately-title {
      font-size: 32px;
      font-weight: 400;
      @include themeify {
        color: themed("color-white");
      }
      line-height: 45px;
      padding-top: 120px;
    }

    .immediately-btn {
      width: 266px;
      height: 44px;
      @include themeify {
        background-color: themed("color-white");
      }
      border-radius: 5px;
      line-height: 44px;
      margin: 0 auto;
      margin-top: 78px;
      @include themeify {
        color: themed("color-primary");
      }
    }

    .immediately-btn:hover {
      background: rgba(255, 255, 255, 0.8);
      cursor: pointer;
    }
  }
}
</style>

/*
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-10-17 17:48:35
 * @LastEditTime: 2019-10-25 16:37:30
 * @LastEditors: Please set LastEditors
 */
import Vue from 'vue'
import Router from 'vue-router'
// import Store from '../store'
// import Common from '@/utils/common'

Vue.use(Router)

const routes = []

const requireContext = require.context('./routes', true, /.*\.js/)
requireContext.keys().map(key => {
  routes.push(...requireContext(key).default)
})

routes.push({
  path: '*',
  redirect: '/'
})

const routerPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(error => error)
}

// 在按下 后退/前进 按钮时，简单地让页面滚动到顶部或原来的位置
const scrollBehavior = (to, from, savedPosition) => {
  // keep-alive 返回缓存页面后记录浏览位置
  if (savedPosition && to.meta.keepAlive) {
    return savedPosition
  }
  // 异步滚动操作
  return new Promise(resolve => {
    setTimeout(() => {
      resolve({ x: 0, y: 1 })
    }, 0)
  })
}

const router = new Router({
  // mode: 'history',
  scrollBehavior,
  routes
})

// function getLogin(next, to, frm) {
//     const token = Common.getToken()
//     if (token) {
//         Store
//             .dispatch('setUser')
//             .then(() => {
//                 next()
//             })
//             .catch(() => {
//                 next({
//                     path: '/',
//                     replace: true
//                 })
//             })
//     } else {
//         if (frm) {
//             next({
//                 path: '/',
//                 query: {
//                     // 将跳转的路由path作为参数，登录成功后跳转到该路由
//                     redirect: to.fullPath
//                 },
//                 replace: true
//             })
//         } else {
//             // document.title = `${to.meta.title}-内部业务系统`
//             next()
//         }
//     }
// }

// router.beforeEach((to, from, next) => {
//     Common.axiosUpdate()
//     if (to.meta.requireAuth) {
//         if (Store.getters.token) {
//             next()
//         } else {
//             getLogin(next, to, true)
//         }
//     } else {
//         if (Store.getters.token === null) {
//             getLogin(next, to, false)
//         } else {
//             next()
//         }
//     }
// })

export default router

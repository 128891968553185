<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-10-17 17:44:35
 * @LastEditTime: 2019-10-25 15:21:30
 * @LastEditors: Please set LastEditors
 -->
<template>
  <Menu mode="horizontal"
        theme="primary"
        active-name="1"
        class="header-menu">
    <Row class="header_row">
      <Col span="18">
      <MenuItem name="1"
                to="/">
        <img src="static/img/logo.png"
             class="logo"
             alt />
        <span class="ft22 pd-left62">鸽纸电签</span>
      </MenuItem>
      <MenuItem name="2"
                to="/fction">
        产品功能
      </MenuItem>
      <MenuItem name="3">
        <Dropdown transfer-class-name="dropdown-item">
          <a href="javascript:void(0)"
             class="menu-hover"
             @click="goScene">应用场景</a>
          <DropdownMenu slot="list">
            <DropdownItem @click.native="$router.push('/scene/globaltrade')">
              对外贸易
            </DropdownItem>
            <DropdownItem @click.native="$router.push('/scene/human')">
              人力资源
            </DropdownItem>
            <DropdownItem @click.native="$router.push('/scene/internet')">
              互联网平台
            </DropdownItem>
            <DropdownItem @click.native="$router.push('/scene/lease')">
              租赁服务
            </DropdownItem>
            <DropdownItem @click.native="$router.push('/scene/fabrication')">
              制造行业
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </MenuItem>
      <MenuItem name="4"
                to="/pricing">
        定价
      </MenuItem>
      <MenuItem name="5">
        帮助
      </MenuItem>
      <MenuItem name="6">
        关于我们
      </MenuItem>
      </Col>
      <Col span="6"
           class="text-right">
      <a class="login-btn">登录</a>
      <a class="fast-login-btn">快速注册</a>
      </Col>
    </Row>
  </Menu>
</template>
<script>
export default {
  data() {
    return {}
  },
  methods: {
    goScene() {
      this.$router.push('/scene/index')
    }
  }
}
</script>
<style lang="scss">
.header-menu {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 5;
  margin: auto;
  width: 100%;
  @include themeify {
    background-color: themed("color-primary");
  }
  .header_row {
    width: 1200px;
    margin: 0 auto;
    .logo {
      position: absolute;
      top: 50%;
      width: 46px;
      height: 32px;
      margin-top: -18px;
    }
    .pd-left62 {
      padding-left: 62px;
    }
    .login-btn {
      padding: 7px 15px;
      border: 1px solid #fff;
      @include borderRadius;
      @include themeify {
        color: themed("color-white");
      }
    }
    .fast-login-btn {
      margin-left: 16px;
      padding: 7px 15px;
      border: 1px solid #fff;
      @include themeify {
        background-color: themed("color-white");
      }
      @include borderRadius;
      @include themeify {
        color: themed("color-primary");
      }
    }
    .menu-hover {
      @include themeify {
        color: themed("color-white");
      }
    }
    .dropdown-item {
      li {
        padding: 15px 40px;
      }
      li:hover {
        background-color: #ddd;
      }
    }
  }
}
</style>

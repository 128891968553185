/*
 * @Author: your name
 * @Date: 2019-10-23 11:11:15
 * @LastEditTime: 2019-10-24 09:28:51
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \web\src\router\routes\scene.js
 */

const router = [
    {
        path: '/scene/index',
        name: 'scene',
        component: () =>
            import(/* webpackChunkName: "index" */ '@/views/scene/index'),
        meta: {
            title: '应用场景'
        }
    },
    {
        path: '/scene/human',
        name: 'human',
        component: () =>
            import(/* webpackChunkName: "index" */ '@/views/scene/human'),
        meta: {
            title: '人力资源'
        }
    },
    {
        path: '/scene/internet',
        name: 'internet',
        component: () =>
            import(/* webpackChunkName: "index" */ '@/views/scene/internet'),
        meta: {
            title: '互联网平台'
        }
    },
    {
        path: '/scene/lease',
        name: 'lease',
        component: () =>
            import(/* webpackChunkName: "index" */ '@/views/scene/lease'),
        meta: {
            title: '租赁服务'
        }
    },
    {
        path: '/scene/fabrication',
        name: 'fabrication',
        component: () =>
            import(/* webpackChunkName: "index" */ '@/views/scene/fabrication'),
        meta: {
            title: '制造行业'
        }
    },
    {
        path: '/scene/globaltrade',
        name: 'globaltrade',
        component: () =>
            import(/* webpackChunkName: "index" */ '@/views/scene/globaltrade'),
        meta: {
            title: '对外贸易'
        }
    }
]

export default router
/*
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-10-18 16:23:12
 * @LastEditTime: 2019-10-24 17:13:42
 * @LastEditors: Please set LastEditors
 */
// 首屏优化
import Index from '@/views/index'

const router = [
    {
        path: '/',
        name: 'index',
        component: Index,
        meta: {
            title: '链录电签 | 专业的商务电子合同签署专家'
        }
    },
    {
        path: '/check',
        name: 'check',
        component: () =>
            import(/* webpackChunkName: "index" */ '@/views/check'),
        meta: {
            title: '合同查询'
        }
    },
    {
        path: '/fction',
        name: 'fction',
        component: () =>
            import(/* webpackChunkName: "index" */ '@/views/fction'),
        meta: {
            title: '产品功能'
        }
    },
    {
        path: '/pricing',
        name: 'pricing',
        component: () =>
            import(/* webpackChunkName: "index" */ '@/views/pricing'),
        meta: {
            title: '定价'
        }
    },
    {
        path: '/help',
        name: 'help',
        component: () =>
            import(/* webpackChunkName: "index" */ '@/views/help'),
        meta: {
            title: '帮助'
        }
    }
]

export default router